/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~quill/dist/quill.snow.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";


//adding scroll-hidden class to hide scroll bar globally wherever the class is applied
.scroll-hidden::-webkit-scrollbar {
    display: none;
}

// Hide all elements
body.barcode-scanner-active {
    visibility: hidden;
    --background: transparent;
    --ion-background-color: transparent;
}
  
// Show only the barcode scanner modal
.barcode-scanning-modal {
    visibility: visible;
}

@media (prefers-color-scheme: dark) {
    .barcode-scanning-modal {
      --background: transparent;
      --ion-background-color: transparent;
    }
  }

agm-map {
    .agm-map-container-inner {
        border-radius: 14px !important;
    }
}

.addEmployeeModal {
    &::part(content) {
        background-color: transparent; 
        box-shadow: none;
    }
}

.addChildVenueModal {
    &::part(content) {
        background-color: transparent; 
        box-shadow: none;
    }
}

.addEventModal {
    &::part(content) {
        background-color: transparent; 
        box-shadow: none;
    }
}

.preferredPromoterModal {
    &::part(content) {
        background-color: transparent;
        box-shadow: none;
    }
}

ion-loading.custom-loading {
    --background: #b0a4e6;
    --spinner-color: #6149cd;

    color: #6149cd;
}

ion-item.vipToggleItem:host {
    --padding-start: 0 !important;
}

//ADD TAG MODAL
.add-tag-modal {
    --height: 300px;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.add-tag-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

.add-tag-modal ion-toolbar {
    --background: rgb(68, 51, 144);
    --color: white;
}

.add-tag-modal ion-content,
.add-tag-modal ion-content {
    --background: rgb(46, 46, 63);
}


//BANNED DETAILS MODAL
.banned-details-modal {
    --height: 600px;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.banned-details-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

.banned-details-modal ion-toolbar {
    --background: rgb(68, 51, 144);
    --color: white;
}

.banned-details-modal ion-content,
.banned-details-modal ion-content {
    --background: rgb(46, 46, 63);
}

//Sell ticket tier now modal
ion-modal.promote-sell-ticket-tier-now-modal {
    --min-height: 400px;
    --height: 400px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
        --height: 356px;
    }
}

//Sell table modal
ion-modal.promote-sell-table-modal {
    --min-height: 600px;
    --height: 600px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
        --height: 356px;
    }
}


//Ticket tier description/info modal
ion-modal.promote-ticket-tier-description-modal,
ion-modal.tier-promoters-modal {
    --min-height: 400px;
    --height: 400px;
    --max-height: 600px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}
ion-modal.edit-tier-promoters-modal,
ion-modal.add-tier-promoters-modal {
    --min-height: 700px;
    --height: 700px;
    --max-height: 700px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}


//Promoter add guest, excel upload, view list guest, view all table tier, and view all ticket tier modals
ion-modal.promote-add-guest-modal,
ion-modal.promote-excel-upload-modal,
ion-modal.promote-view-list-guests-modal,
ion-modal.view-all-table-tiers-modal,
ion-modal.view-all-ticket-tiers-modal,
ion-modal.view-list-insights-modal {
    --min-height: 700px;
    --height: 700px;
    --max-height: 700px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}


//change viewing venue modal
ion-modal.change-venue-modal {
    --min-height: 400px;
    --height: 400px;
    --width: 300px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}


//check in multiple guests modal
ion-modal.check-in-multiple-guests-modal {
    --min-height: 320px;
    --height: 320px;
    --width: 300px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}


//update guest gender modal
ion-modal.update-guest-gender-modal {
    --min-height: 320px;
    --height: 320px;
    --width: 300px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}

//ticketing page modals
ion-modal.ticketing-view-guest-details-modal,
ion-modal.ticket-analytics-modal {

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;

        ::-webkit-scrollbar {
            display: none;
        }
    }
}

//edit event modals
ion-modal.edit-event-info-modal,
ion-modal.edit-event-ticket-tier-modal,
ion-modal.add-event-ticket-tier-modal {
    --min-height: 750px;
    --height: 750px;
    --max-height: 750px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}

ion-modal.duplicate-event-modal {
    --min-height: 300px;
    --height: 300px;
    --max-height: 300px;

    ion-toolbar {
        --background: #312567;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #d0c8f0;
    }
}

ion-modal.view-event-qr-code-modal {
    --min-height: 325px;
    --height: 325px;
    --max-height: 325px;
    --width: 300px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}


//accounting modals
ion-modal.cashflow-modal,
ion-modal.itemized-payments-modal,
ion-modal.payouts-modal {
    --min-height: 700px;
    --height: 700px;
    --max-height: 700px;
    --width: 700px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }

    //mobile styling
    @media (max-width: 768px) {
        --min-height: 500px;
        --height: 500px;
        --max-height: 500px;
        --width: 100vw;
    }
}

ion-modal.recurring-guest-lists-modal,
ion-modal.edit-recurring-lists-modal {
    --min-height: 400px;
    --height: 400px;
    --width: 350px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}


//TABLE MODALS
ion-modal.default-floor-plan-modal,
ion-modal.reservation-change-table-modal {
    --min-height: 95vh;
    --min-width: 95vw;

    ion-toolbar {
        --background: #2e2e3f;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #171720;
    }
}

ion-modal.add-reservation-socials-modal,
ion-modal.add-company-modal,
ion-modal.update-reservation-guest-genders-modal {
    --min-height: 250px;
    --height: 250px;
    --max-height: 250px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}

ion-modal.reservation-assign-server-modal {
    &::part(content) {
        background-color: transparent !important;
    }
}

ion-modal.attribute-reservation-modal {
    &::part(content) {
        background-color: transparent !important;
    }
}

//CRM Modal Styling
ion-modal.update-customer-phone-modal,
ion-modal.update-customer-job-info-modal {
    --min-height: 400px;
    --height: 400px;
    --max-height: 400px;
    --width: 300px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
        --height: 356px;
    }
}

ion-modal.update-customer-birth-date-modal {
    --min-height: 200px;
    --height: 200px;
    --max-height: 200px;
    --width: 300px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
        --height: 356px;
    }
}

ion-modal.promoter-table-payout-modal {
    --min-height: 500px;
    --height: 500px;
    --max-height: 500px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}

ion-modal.profile-picture-modal,
ion-modal.app-version-modal {
    --min-height: 500px;
    --height: 500px;
    --max-height: 500px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}

ion-modal.add-promoter-modal {
    --min-height: 650px;
    --height: 650px;
    --max-height: 650px;
    --width: 525px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}

//TOAST STYLING
ion-toast.error-toast {
    --background: rgb(254, 226, 226);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(153, 27, 27);
}

ion-toast.warning-toast {
    --background: rgb(255, 237, 213);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(154, 52, 18);
}

ion-toast.success-toast {
    --background: rgb(220, 252, 231);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(22, 101, 52);
}

ion-toast.information-toast {
    --background: rgb(241, 245, 249);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(30, 41, 59);
}

ion-toast.move-reservation-or-remove-table-toast {
    &::part(button){
        --button-color: rgb(154, 52, 18);
    }
}


//SKELETON TEXT STYLING
ion-skeleton-text {
    --background-rgb: 46, 46, 63;
}
ion-skeleton-text.promoter-skeleton-text {
    --background-rgb: 189, 189, 196 !important;
}

.skeleton-item::part(native) {
    background-color: white !important;
}

.skeleton-list,
.skeleton-item,
.skeleton-label {
    background-color: white !important;
}

.promoter-skeleton-item::part(native) {
    background-color: #2e2e3f !important;
}

.promoter-skeleton-list,
.promoter-skeleton-item,
.promoter-skeleton-label {
    background-color: #2e2e3f !important;
}

//DATE TIME STYLING
ion-datetime {
    --background: #171720;
}

// Define light mode styles
@media (prefers-color-scheme: light) {
    ion-datetime {
        --background: #fff;
    }
}
  
// Define dark mode styles
@media (prefers-color-scheme: dark) {
    ion-datetime {
        --background: #171720;
    }
}

ion-datetime-button.unselectedDateTimeButton {
    &::part(native) {
        background-color: #c0c0c5 !important;
        color: #171720 !important;
    }
}

ion-datetime-button.selectedDateTimeButton {
    &::part(native) {
        background-color: #c8f0e3 !important;
        color: #256752 !important;
    }
}

//ACCORDION STYLING
ion-accordion.edit-promoter-discount {
    background-color: transparent;

    ion-item {
        --background: #171720;
        --color: #fff;
    }

    div[slot="content"] {
        background-color: #252532;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-bottom: 8px;
    }
}


//POPOVER STYLING
ion-popover.edit-promoter-discount {
    --width: 300px;
}

// ion-popover.reservation-social-popover {
//     --width: 300px;
// }

ion-popover.ticketing-guest-dangerous-action {
    &::part(content) {
        height: 200px;
        width: 250px;
    }
}

ion-popover.guest-tag-filter {
    --background: #2E2E3F !important;

    &::part(content) {
        height: 300px;
        width: 400px;
        overflow: hidden;
    }

    ion-content {
        &::part(background) {
            --background: #2E2E3F !important;
        }
    }
}

ion-popover.lifetime-spend-filter {
    --background: #2E2E3F !important;

    &::part(content) {
        width: 400px;
        overflow: hidden;
    }

    ion-content {
        &::part(background) {
            --background: #2E2E3F !important;
        }
    }
}

ion-popover.gender-filter {
    --background: #2E2E3F !important;

    &::part(content) {
        width: 200px;
        overflow: hidden;
    }

    ion-content {
        &::part(background) {
            --background: #2E2E3F !important;
        }
    }
}

ion-popover.crm-upload {
    --background: #2E2E3F !important;

    &::part(content) {
        height: 110px;
        width: 300px;
        overflow: hidden;
    }

    ion-content {
        &::part(background) {
            --background: #2E2E3F !important;
        }
    }
}

ion-popover.ticketing-actions {
    --background: #2E2E3F !important;

    &::part(content) {
        width: 250px;
        overflow: hidden !important;
    }

    ion-content {
        &::part(background) {
            --background: #2E2E3F !important;
        }

        &::part(scroll) {
            overflow: hidden !important;
        }
    }
}

ion-popover.copyLinkPopover {
    --background: #2E2E3F !important;

    &::part(content) {
        width: 300px;
        overflow: hidden !important;
    }

    ion-content {
        &::part(background) {
            --background: #2E2E3F !important;
        }

        &::part(scroll) {
            overflow: hidden !important;
        }
    }
}

ion-popover.event-floor-plan-popover {
    &::part(content) {
        width: 250px;
    }
}


//DRAG AND DROP STYLING
ngx-file-drop {
    width: 100%;

    .ngx-file-drop__drop-zone {
        border: 2px dashed #fff !important;
        color: #fff !important;
        height: 200px !important;

        .ngx-file-drop__content {
            color: #fff !important;
            height: 100% !important;
        }
    }
}


//ACTION SHEET STYLING
// ion-action-sheet.profile-action-sheet {
//     --color: #fff;
//     --button-color: #fff;
// }

//Removing number input up/down arrows
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

//setting default quill editor color
// !important this is how you can override the default quill editor color
// quill-editor#table-guest-notes-editor {
//     .ql-container {
//         .ql-editor {
//             background-color: #fff !important;
//         }
//     }
// }
.ql-editor {
    background-color: #fff !important;
    color: #171720 !important;
}

// .message-text-content ol {
//     list-style-type: decimal !important;
//     padding-left: 15px;
//     padding-right: 15px;
// }

// .message-text-content ul {
//     list-style-type: disc !important;
//     padding-left: 15px;
//     padding-right: 15px;
// }

.message-text-content {
    .ql-align-center {
        text-align: center;
    }
    .ql-align-right {
        text-align: right;
    }
    

    .ql-size-huge {
        font-size: 2.5em;
    }
    .ql-size-large {
        font-size: 1.5em;
    }
    .ql-size-small {
        font-size: 0.75em;
    }


    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
    }
    h3 {
        font-size: 1.17em;
    }
    h4 {
        font-size: 1em;
    }
    h5 {
        font-size: 0.83em;
    }
    h6 {
        font-size: 0.67em;
    }


    blockquote {
        border-left: 4px solid #ccc;
        margin-bottom: 5px;
        margin-top: 5px;
        padding-left: 16px;
    }


    ol,
    ul {
        padding-left: 1.5em;
    }
    ol > li,
    ul > li {
        list-style-type: none;
    }
    ul > li::before {
        content: '\2022';
    }
    ul[data-checked=true],
    ul[data-checked=false] {
        pointer-events: none;
    }
    ul[data-checked=true] > li *,
    ul[data-checked=false] > li * {
        pointer-events: all;
    }
    ul[data-checked=true] > li::before,
    ul[data-checked=false] > li::before {
        color: #777;
        cursor: pointer;
        pointer-events: all;
    }
    ul[data-checked=true] > li::before {
        content: '\2611';
    }
    ul[data-checked=false] > li::before {
        content: '\2610';
    }
    li::before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
    }
    li:not(.ql-direction-rtl)::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
    }
    li.ql-direction-rtl::before {
        margin-left: 0.3em;
        margin-right: -1.5em;
    }
    ol li:not(.ql-direction-rtl),
    ul li:not(.ql-direction-rtl) {
        padding-left: 1.5em;
    }
    ol li.ql-direction-rtl,
    ul li.ql-direction-rtl {
        padding-right: 1.5em;
    }
    ol li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-0;
    }
    ol li:before {
        content: counter(list-0, decimal) '. ';
    }
    ol li.ql-indent-1 {
        counter-increment: list-1;
    }
    ol li.ql-indent-1:before {
        content: counter(list-1, lower-alpha) '. ';
    }
    ol li.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-2 {
        counter-increment: list-2;
    }
    ol li.ql-indent-2:before {
        content: counter(list-2, lower-roman) '. ';
    }
    ol li.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-3 {
        counter-increment: list-3;
    }
    ol li.ql-indent-3:before {
        content: counter(list-3, decimal) '. ';
    }
    ol li.ql-indent-3 {
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-4 {
        counter-increment: list-4;
    }
    ol li.ql-indent-4:before {
        content: counter(list-4, lower-alpha) '. ';
    }
    ol li.ql-indent-4 {
        counter-reset: list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-5 {
        counter-increment: list-5;
    }
    ol li.ql-indent-5:before {
        content: counter(list-5, lower-roman) '. ';
    }
    ol li.ql-indent-5 {
        counter-reset: list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-6 {
        counter-increment: list-6;
    }
    ol li.ql-indent-6:before {
        content: counter(list-6, decimal) '. ';
    }
    ol li.ql-indent-6 {
        counter-reset: list-7 list-8 list-9;
    }
    ol li.ql-indent-7 {
        counter-increment: list-7;
    }
    ol li.ql-indent-7:before {
        content: counter(list-7, lower-alpha) '. ';
    }
    ol li.ql-indent-7 {
        counter-reset: list-8 list-9;
    }
    ol li.ql-indent-8 {
        counter-increment: list-8;
    }
    ol li.ql-indent-8:before {
        content: counter(list-8, lower-roman) '. ';
    }
    ol li.ql-indent-8 {
        counter-reset: list-9;
    }
    ol li.ql-indent-9 {
        counter-increment: list-9;
    }
    ol li.ql-indent-9:before {
        content: counter(list-9, decimal) '. ';
    }
    .ql-indent-1:not(.ql-direction-rtl) {
        padding-left: 3em;
    }
    li.ql-indent-1:not(.ql-direction-rtl) {
        padding-left: 4.5em;
    }
    .ql-indent-1.ql-direction-rtl.ql-align-right {
        padding-right: 3em;
    }
    li.ql-indent-1.ql-direction-rtl.ql-align-right {
        padding-right: 4.5em;
    }
    .ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 6em;
    }
    li.ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 7.5em;
    }
    .ql-indent-2.ql-direction-rtl.ql-align-right {
        padding-right: 6em;
    }
    li.ql-indent-2.ql-direction-rtl.ql-align-right {
        padding-right: 7.5em;
    }
    .ql-indent-3:not(.ql-direction-rtl) {
        padding-left: 9em;
    }
    li.ql-indent-3:not(.ql-direction-rtl) {
        padding-left: 10.5em;
    }
    .ql-indent-3.ql-direction-rtl.ql-align-right {
        padding-right: 9em;
    }
    li.ql-indent-3.ql-direction-rtl.ql-align-right {
        padding-right: 10.5em;
    }
    .ql-indent-4:not(.ql-direction-rtl) {
        padding-left: 12em;
    }
    li.ql-indent-4:not(.ql-direction-rtl) {
        padding-left: 13.5em;
    }
    .ql-indent-4.ql-direction-rtl.ql-align-right {
        padding-right: 12em;
    }
    li.ql-indent-4.ql-direction-rtl.ql-align-right {
        padding-right: 13.5em;
    }
    .ql-indent-5:not(.ql-direction-rtl) {
        padding-left: 15em;
    }
    li.ql-indent-5:not(.ql-direction-rtl) {
        padding-left: 16.5em;
    }
    .ql-indent-5.ql-direction-rtl.ql-align-right {
        padding-right: 15em;
    }
    li.ql-indent-5.ql-direction-rtl.ql-align-right {
        padding-right: 16.5em;
    }
    .ql-indent-6:not(.ql-direction-rtl) {
        padding-left: 18em;
    }
    li.ql-indent-6:not(.ql-direction-rtl) {
        padding-left: 19.5em;
    }
    .ql-indent-6.ql-direction-rtl.ql-align-right {
        padding-right: 18em;
    }
    li.ql-indent-6.ql-direction-rtl.ql-align-right {
        padding-right: 19.5em;
    }
    .ql-indent-7:not(.ql-direction-rtl) {
        padding-left: 21em;
    }
    li.ql-indent-7:not(.ql-direction-rtl) {
        padding-left: 22.5em;
    }
    .ql-indent-7.ql-direction-rtl.ql-align-right {
        padding-right: 21em;
    }
    li.ql-indent-7.ql-direction-rtl.ql-align-right {
        padding-right: 22.5em;
    }
    .ql-indent-8:not(.ql-direction-rtl) {
        padding-left: 24em;
    }
    li.ql-indent-8:not(.ql-direction-rtl) {
        padding-left: 25.5em;
    }
    .ql-indent-8.ql-direction-rtl.ql-align-right {
        padding-right: 24em;
    }
    li.ql-indent-8.ql-direction-rtl.ql-align-right {
        padding-right: 25.5em;
    }
    .ql-indent-9:not(.ql-direction-rtl) {
        padding-left: 27em;
    }
    li.ql-indent-9:not(.ql-direction-rtl) {
        padding-left: 28.5em;
    }
    .ql-indent-9.ql-direction-rtl.ql-align-right {
        padding-right: 27em;
    }
    li.ql-indent-9.ql-direction-rtl.ql-align-right {
        padding-right: 28.5em;
    }
}
//Messaging styles


// #root {
//   display: flex;
//   height: 100%;
//   border-radius: 10px;

//   stream-channel-list {
//     width: 30%;
//   }

//   stream-channel {
//     width: 100%;
//   }

//   stream-thread {
//     width: 45%;
//   }
// }
